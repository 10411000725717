import {twMerge} from '@/stylesheets/twMerge';
import {type Mode} from '@/components/base/types';
import type {
  HeadingGroupProps,
  HeadingGroupContentProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import ButtonGroup, {
  type ButtonGroupContentProps,
} from '@/components/base/modules/ButtonGroup/ButtonGroup';

interface ConversionProps
  extends HeadingGroupContentProps,
    ButtonGroupContentProps {
  className?: string;
  headingClassName?: string;
  headingSize?: HeadingGroupProps['size'];
  buttonsClassName?: string;
  mode?: Mode;
}

export default function Conversion({
  className,
  headingClassName,
  headingSize,
  buttonsClassName,
  mode = 'light',
  kicker,
  headingHtml,
  subheadHtml,
  buttons,
  disclaimerHtml,
}: ConversionProps) {
  const center = true;
  const colProps = {
    start: {xs: 1, sm: 1, md: 2, lg: 3, xl: 3},
    span: {xs: 4, sm: 8, md: 10, lg: 8, xl: 8},
  };
  const headingGroupProps = {
    className: headingClassName,
    kicker,
    headingHtml,
    subheadHtml,
    center,
    mode,
    size: headingSize,
  };
  const buttonGroupProps = {
    className: twMerge('mt-md', buttonsClassName),
    buttons,
    disclaimerHtml,
    center,
    mode,
    size: 'large' as 'large' | 'small',
  };
  return (
    <Grid className={className}>
      <Col {...colProps}>
        <HeadingGroup {...headingGroupProps} />
        <ButtonGroup {...buttonGroupProps} />
      </Col>
    </Grid>
  );
}
